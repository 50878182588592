import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('grid-layout',_vm._b({attrs:{"title":_vm.$t('Sales report')},on:{"paramsChanged":_vm.reloadCollection},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('sales-report-export-button',{attrs:{"params":_vm.params}})]},proxy:true},{key:"scrollable.content.top",fn:function(){return [_c('div',{staticClass:"d-flex flex-column flex-gap-16"},[_c('sales-report-query-inputs',{staticClass:"d-flex flex-gap-16 my-4 flex-column",on:{"params:changed":_vm.reloadCollection},model:{value:(_vm.params),callback:function ($$v) {_vm.params=$$v},expression:"params"}})],1),_c(VDivider,{staticClass:"my-2"})]},proxy:true},{key:"item.grouped_by",fn:function(ref){
var item = ref.item;
return [_c('sales-report-grouped-by-labels',{staticClass:"text-capitalize my-4",attrs:{"grouped-by":item.grouped_by,"groups":_vm.paramsGroup}})]}},{key:"item.order_number",fn:function(ref){
var item = ref.item;
return [_c(_vm.orderCanRead || _vm.orderCanUpdate || _vm.orderCanDelete ? 'router-link' : 'span',{tag:"router-link",attrs:{"to":{ name: 'single-order', params: { id: item.order_number } }}},[_vm._v(" "+_vm._s(item.order_number)+" ")])]}},{key:"item.meta.total",fn:function(ref){
var item = ref.item;
return [(item.meta && item.meta.total)?_c('sales-report-summary',{attrs:{"params":_vm.params,"value":item.meta.total}}):_vm._e()]}},{key:"item.meta.total_gross",fn:function(ref){
var item = ref.item;
return [(item.meta && item.meta.total_gross)?_c('sales-report-summary',{attrs:{"params":_vm.params,"value":item.meta.total_gross}}):_vm._e()]}},{key:"item.meta.total_due",fn:function(ref){
var item = ref.item;
return [(item.meta && item.meta.total_due)?_c('sales-report-summary',{attrs:{"params":_vm.params,"value":item.meta.total_due}}):_vm._e()]}},{key:"item.meta.total_taxes",fn:function(ref){
var item = ref.item;
return [(item.meta && item.meta.total_taxes)?_c('sales-report-summary',{attrs:{"params":_vm.params,"value":item.meta.total_taxes}}):_vm._e()]}},{key:"item.meta.total_discount",fn:function(ref){
var item = ref.item;
return [(item.meta && item.meta.total_discount)?_c('sales-report-summary',{attrs:{"params":_vm.params,"value":item.meta.total_discount}}):_vm._e()]}},{key:"item.meta.total_refunded",fn:function(ref){
var item = ref.item;
return [(item.meta && item.meta.total_refunded)?_c('sales-report-summary',{attrs:{"params":_vm.params,"value":item.meta.total_refunded}}):_vm._e()]}},{key:"item.meta.items_count",fn:function(ref){
var item = ref.item;
return [(item.meta && item.meta.items_count)?_c('sales-report-summary',{attrs:{"params":_vm.params,"value":item.meta.items_count}}):_vm._e()]}},{key:"item.meta.quantity",fn:function(ref){
var item = ref.item;
return [(item.meta && item.meta.quantity)?_c('sales-report-summary',{attrs:{"params":_vm.params,"value":item.meta.quantity}}):_vm._e()]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [(item.total)?_c('span',[_vm._v(_vm._s(_vm.$format.money.withStoreCurrency(item.total)))]):_vm._e()]}},{key:"item.total_gross",fn:function(ref){
var item = ref.item;
return [(item.total_gross)?_c('span',[_vm._v(_vm._s(_vm.$format.money.withStoreCurrency(item.total_gross)))]):_vm._e()]}},{key:"item.total_taxes",fn:function(ref){
var item = ref.item;
return [(item.total_taxes)?_c('span',[_vm._v(_vm._s(_vm.$format.money.withStoreCurrency(item.total_taxes)))]):_vm._e()]}},{key:"item.total_discount",fn:function(ref){
var item = ref.item;
return [(item.total_discount)?_c('span',[_vm._v(_vm._s(_vm.$format.money.withStoreCurrency(item.total_discount)))]):_vm._e()]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$format.timezone.getFormattedTime(item.created_at)))])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$format.timezone.getFormattedTime(item.updated_at)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"depressed":"","color":"primary","small":"","to":_vm.redirectTo(item.grouped_by).value}},[_vm._v(" "+_vm._s(_vm.$t('View details'))+" "),_c('rtl-chevron-icon')],1)]}}])},'grid-layout',{ grid: _vm.grid, params: _vm.params, options: _vm.options },false))}
var staticRenderFns = []

export { render, staticRenderFns }